@font-face {
	font-family: Heebo-Light;
	src: url("../fonts/static/Heebo-Light.ttf");
}
@font-face {
	font-family: Heebo-ExLight;
	src: url("../fonts/static/Heebo-ExtraLight.ttf");
}
@font-face {
	font-family: Heebo-Medium;
	src: url("../fonts/static/Heebo-Medium.ttf");
}
@font-face {
	font-family: Heebo-Regular;
	src: url("../fonts/static/Heebo-Regular.ttf");
}
@font-face {
	font-family: Heebo-SemiBold;
	src: url("../fonts/static/Heebo-SemiBold.ttf");
}
@font-face {
	font-family: Heebo-Thin;
	src: url("../fonts/static/Heebo-Thin.ttf");
}
@font-face {
	font-family: Heebo-ExtraBold;
	src: url("../fonts/static/Heebo-ExtraBold.ttf");
}
@font-face {
	font-family: Heebo-Bold;
	src: url("../fonts/static/Heebo-Bold.ttf");
}
@font-face {
	font-family: Heebo-Black;
	src: url("../fonts/static/Heebo-Black.ttf");
}
@font-face {
	font-family: Heebo-Variable;
	src: url("../fonts/Heebo-VariableFont_wght.ttf");
}

@font-face {
	font-family: 'icomoon';
	src:  url('../fonts/icomoon.eot?259bn6');
	src:  url('../fonts/icomoon.eot?259bn6#iefix') format('embedded-opentype'),
	  url('../fonts/icomoon.ttf?259bn6') format('truetype'),
	  url('../fonts/icomoon.woff?259bn6') format('woff'),
	  url('../fonts/icomoon.svg?259bn6#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
  
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  }
  
  .icon-Tour:before {
	content: "\e916";
  }
  .icon-Edit:before {
	content: "\e90f";
  }
  .icon-Add:before {
	content: "\e900";
  }
  .icon-Close:before {
	content: "\e901";
  }
  .icon-Club:before {
	content: "\e902";
  }
  .icon-Dashboard:before {
	content: "\e903";
  }
  .icon-Date:before {
	content: "\e904";
  }
  .icon-Delete:before {
	content: "\e905";
  }
  .icon-Dislike:before {
	content: "\e906";
  }
  .icon-down:before {
	content: "\e907";
  }
  .icon-Image:before {
	content: "\e908";
  }
  .icon-Info:before {
	content: "\e909";
  }
  .icon-Like:before {
	content: "\e90a";
  }
  .icon-Members:before {
	content: "\e90b";
  }
  .icon-More:before {
	content: "\e90c";
  }
  .icon-Photo:before {
	content: "\e90d";
  }
  .icon-Promotion:before {
	content: "\e90e";
  }
  .icon-Settings:before {
	content: "\e910";
  }
  .icon-Sort:before {
	content: "\e911";
  }
  .icon-Time:before {
	content: "\e912";
  }
  .icon-Unlink:before {
	content: "\e913";
  }
  .icon-Up:before {
	content: "\e914";
  }
  .icon-Search:before {
	content: "\e915";
  }
  

body {
	font-family: Heebo-Regular; 
	font-size: 16px;
	height: 100%;
	margin: 0;
}

.HeeboMedium {
    font-family: Heebo-Medium !important; 
}

.HeeboLight {
    font-family: Heebo-Light !important; 
}

/**navigation**/
.nav-container{
    /* width: 100%; */
    top: 0px;
    padding: 0px 0px 0px;
	height: 70px;
	/* ---------- */
	width: 100%;
	position: relative;
}
.nav-checkbox{
    display:grid;
    height: 70px;
}
.tab-content{
	position: fixed;
    justify-self: left;
    margin: 0px;
	padding: 0px;
	/* width: 100%; */
	/* ------ */
	/* position: sticky; */
	/* position: -webkit-sticky; */
	/* position: -moz-sticky; */
	/* position: -ms-sticky; */
	/* position: -o-sticky; */
	/* top: 80px; */
	background-color: #ffffff;
	border-radius: 35px;
	z-index: 1000;
	/* width: 92.1%; */
	width: 94%;
}
.tab-content li{
	display: inline-block;
	/* width: 16.66%; */
}
.tab-content li a{
	height: 30px;
	padding: 20px;
	min-width: 200px; 
}


/**section**/
section{
	padding-left: 20px;
	padding-top: 20px;
	padding-right: 20px;
	padding-bottom: 10px;
    text-align: left;
    border-radius: 15px;
    margin-top: 15px;
	margin-bottom: 15px;
	background-color: #ffffff;
}


/**style on scroll**/
.nav-container.scroll{
}
.nav-container.scroll li{
    color: #273179;
    border-radius: 35;
}

/**active navigation**/
.nav-container.scroll a.active{
    border-bottom: 1px solid #ffffff;
    background-color: #F9E4B2;
    border-radius: 35;
    
}
/**media screen**/
@media screen and (max-width: 1531px){
	.tab-content{
		position: fixed;
		justify-self: left;
		margin: 0px;
		padding: 0px;
		background-color: #ffffff;
		border-radius: 35px;
		z-index: 1000;
		width: 93.4%;
	}
	.tab-content li a{
		height: 30px;
		padding: 20px;
		min-width: 190px; 
	}
}

@media screen and (max-width: 1490px){
	.tab-content{
		position: fixed;
		justify-self: left;
		margin: 0px;
		padding: 0px;
		background-color: #ffffff;
		border-radius: 35px;
		z-index: 1000;
		width: 93.5%;
	}
	.tab-content li a{
		height: 30px;
		padding: 20px;
		min-width: 170px; 
	}
}

@media screen and (max-width: 1355px){
	.tab-content{
		position: fixed;
		justify-self: left;
		margin: 0px;
		padding: 0px;
		background-color: #ffffff;
		border-radius: 35px;
		z-index: 1000;
		width: 93%;
	}
	.tab-content li a{
		height: 30px;
		padding: 20px;
		min-width: 160px; 
	}
}

@media screen and (max-width: 1310px){
	.tab-content{
		position: fixed;
		justify-self: left;
		margin: 0px;
		padding: 0px;
		background-color: #ffffff;
		border-radius: 35px;
		z-index: 1000;
		width: 93%;
	}
	.tab-content li a{
		height: 30px;
		padding: 18px;
		min-width: 140px; 
	}
}

@media screen and (max-width: 1200px){
	.tab-content{
		position: fixed;
		justify-self: left;
		margin: 0px;
		padding: 0px;
		background-color: #ffffff;
		border-radius: 35px;
		z-index: 1000;
		width: 92%;
	}
	.tab-content li a{
		height: 30px;
		padding: 18px;
		min-width: 100px; 
	}
}
@media screen and (max-width: 1100px){
	.tab-content{
		position: fixed;
		justify-self: left;
		margin: 0px;
		padding: 0px;
		background-color: #ffffff;
		border-radius: 35px;
		z-index: 1000;
		width: 91%;
	}
	.tab-content li a{
		height: 30px;
		padding: 15px;
		min-width: 50px; 
	}
}
@media screen and (max-width: 1060px){
	.nav-container{
		visibility: hidden;
	}
	.nav-checkbox{
		visibility: hidden;
	}
	.tab-content{
		visibility: hidden;
	}
	.tab-content li a{
		visibility: hidden; 
	}
}
@media screen and (max-width: 768px){
	.nav-container{
		height:0px;
	}
	.nav-checkbox{
		display:grid;
		height: 0px;
	}
	.tab-content{
		display: grid;
		top: 0;
		margin-top: 0;
		grid-template-columns: 1fr;
		grid-column: 1/4;
		text-align: left;
		max-height: 0;
		overflow: hidden;
		justify-self: left;
		transition: all ease-in-out;
	}
	.tab-nav-label{
		display: grid;
		cursor: pointer;
		grid-column: 3;
		padding-right: 20px;
	}
	.nav-checkbox input:checked ~ .tab-content{
		max-height: 400px;
	}
	.tab-content li{
		padding: 10px 0;
	}
	.nav-checkbox input:checked ~ .tab-nav-label{
		color:#273179
	}
}
