
  .appbody{
    position: relative;
  }
  .rightWraper{
   margin-left: 66px;
  }
  .leftwraper{
    position: fixed;
    width: 66px;
    max-width: 66px;
    top:0;
    left: 0;
    bottom: 0px;
  }
  
  
  .leftSidebar{
    position: fixed;
    width: 66px;
    max-width: 66px;
    z-index: 1600;
    height: 100%;
    top:0;
    left: 0;
    bottom: 0;
  }
  

  @media screen and (max-width: 960px) {
    .leftwraper{
      display: none;
    }
    .rightWraper{
      margin-left: 0;
    }
  }