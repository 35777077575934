body {
  margin: 0;
  background-color:#F7FAFF;
}

* {
  scrollbar-width: thin !important;
}

::-webkit-scrollbar {
width: 8px;
}
::-webkit-scrollbar-thumb {
background: #e9e9e9; 
}
::-webkit-scrollbar-thumb:hover {
background: #fefefe; 
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}